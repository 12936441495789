import styled, { css } from 'styled-components';

var OOS_BUTTON_STYLES =
  'color:#aaa;background-color:transparent;border: 1px solid #aaa;cursor: not-allowed!important; pointer-events: none';

const Button = styled.button`
  outline: 0;
  cursor: pointer;
  ${props => css`
    ${props.fullWidth ? 'width: 100%;' : ''}
    ${props.config?.button_styles}
    ${!props.isAvailable ? OOS_BUTTON_STYLES : ''}
  `}
`;

export default Button;
