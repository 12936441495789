declare var gtag: any;

export const googleAdsHandler = (event, sendTo) => {
  try {
    const { action, value } = event.data;

    if (!/^bc\-.*/g.test(action) || typeof gtag === 'undefined' || !sendTo) {
      return;
    }

    if (action === 'bc-sale') {
      const { payload, items } = value;

      gtag('event', 'conversion', {
        send_to: sendTo,
        value: payload.total / 100,
        items: items.map(item => ({
          id: item.id,
          quantity: item.quantity,
        })),
        currency: 'USD',
        transaction_id: payload.id,
      });
    }
  } catch (e) {
    console.log('google ads error', e);
  }
};
