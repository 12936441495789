class client {
  private url;

  constructor(env) {
    this.url =
      env === 'local'
        ? 'http://localhost:8080'
        : env === 'production'
          ? 'https://api.accelpay.io'
          : 'https://stage.accelpay.io';
  }

  get(route: string) {
    return fetch(`${this.url}/v1${route}`, {
      headers: {
        Accept: 'application/json',
      },
    });
  }
}

export default client;
