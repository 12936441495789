import { WIX_SITES } from './lib/constants';

const wixLoader = (dom, container) => {
  if (container) {
    new MutationObserver(mutationList => {
      mutationList.forEach(mutation => {
        if (
          mutation.addedNodes.length > 0 &&
          (['PAGES_CONTAINER', 'SITE_PAGES'].includes(
            mutation.target.parentElement.parentElement.id,
          ) ||
            ['PAGES_CONTAINER', 'SITE_PAGES'].includes(
              mutation.target.parentElement.id,
            ))
        ) {
          dom.attachAllElements();
        }
      });
    }).observe(container, { subtree: true, childList: true });

    /* For Wix lightbox widget */
    new MutationObserver(mutationList => {
      mutationList.forEach(mutation => {
        mutation.addedNodes.forEach(async (addedNode: any) => {
          if (addedNode.id === 'POPUPS_ROOT') {
            dom.attachAllElements();
          }
        });
      });
    }).observe(document, { subtree: true, childList: true });
  }
};

export const wixListener = dom => {
  if (WIX_SITES.some(site => window.location.hostname.includes(site))) {
    console.log('add wix listener');
    const pagesContainer = document.querySelector('#PAGES_CONTAINER');
    const pagesContainerBackup = document.querySelector('#SITE_PAGES');
    wixLoader(dom, pagesContainer);
    wixLoader(dom, pagesContainerBackup);
  }
};

export const spaListener = dom => {
  let lastUrl = location.href;
  new MutationObserver(async () => {
    const url = location.href;
    if (url !== lastUrl) {
      console.log(`Route change: ${lastUrl} to ${url}`);
      lastUrl = url;
      dom.attachAllElements();
    }
  }).observe(document, { subtree: true, childList: true });
};
