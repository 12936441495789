export const STAGING_SITES = [
  'localhost',
  'demo.accelpay.io',
  'stage-cart.accelpay.io',
  'stage-checkout.accelpay.io',
  'vercel.app',
  'accelpay-dev.loca.lt',
  'tryaccelpay.com',
];

export const SENTRY_DSN =
  'https://ee2d28ef5cb74624a3e7f8ef0e269c92@o4504532072464384.ingest.sentry.io/4504532073906176';

export const WIX_SITES = [
  'supergayspirits.com',
  'spring44.com',
  'ricouspirits.com',
  'luxardousa.com',
  'cayugaclear.com',
  'el-cristiano.com',
  'kokorospirits.com',
  'himbrimi.com',
  'sanmartintequila.com',
  'gyterytevodka.com',
  'jacksonmccreawhiskey.com',
  'cheerswithabisola.com',
  'montaukila.com',
  'silverstarspirits.com',
  'roaringriverdistillery.com',
  'creekwaterwhiskey.com',
  'bearfightwhiskey.com',
  'numbskull-whiskey.com',
];

export const LOCATIONS = [
  { label: 'USD', country: 'us', locale: 'en-US', currency: 'usd' },
  { label: 'IE', country: 'ie', locale: 'en-IE', currency: 'eur' },
  { label: 'AT', country: 'at', locale: 'en-AT', currency: 'eur' },
  { label: 'BE', country: 'be', locale: 'en-BE', currency: 'eur' },
  { label: 'DK', country: 'dk', locale: 'en-DK', currency: 'eur' },
  { label: 'FI', country: 'fi', locale: 'en-FI', currency: 'eur' },
  { label: 'FR', country: 'fr', locale: 'en-FR', currency: 'eur' },
  { label: 'DE', country: 'de', locale: 'en-DE', currency: 'eur' },
  { label: 'IT', country: 'it', locale: 'en-IT', currency: 'eur' },
  { label: 'ES', country: 'es', locale: 'en-ES', currency: 'eur' },
  { label: 'SE', country: 'se', locale: 'en-SE', currency: 'eur' },
  { label: 'CH', country: 'ch', locale: 'en-CH', currency: 'eur' },
  { label: 'GB', country: 'gb', locale: 'en-GB', currency: 'eur' },
];

export const COUNTRY_COOKIE_KEY = 'accelpay-country-preference';

export const LS_KEY = 'accelpay/cart';
