import { LS_KEY } from './constants';

export const isAccelPayMessage = e => /^bc\-.*/g.test(e?.data?.action);

export const formatPrice = (
  amount: number,
  locale = 'en-US',
  currency = 'USD',
) =>
  typeof amount === 'number'
    ? new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
        amount / 100,
      )
    : '';

export const getAddress = () =>
  JSON.parse(sessionStorage.getItem(`${LS_KEY}/address`) || '{}');

export const addressToString = address =>
  address?.line1
    ? `${address.line1}${address.line2 ? ` ${address.line2}` : ''} ${
        address.city
      }, ${address.state}`
    : '';

export const capitalizeFirstLetter = (string?: string) => {
  if (!string || !string.length) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
};
