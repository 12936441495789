import Dom from './dom';
import * as Sentry from '@sentry/browser';
import { wixListener, spaListener } from './listeners';
import client from './lib/api';
import { SENTRY_DSN } from './lib/constants';
import { klaviyoHandler } from './conversions/klaviyo';
import { googleAdsHandler } from './conversions/googleads';
import { fbPixelHandler } from './fb';
import { isAccelPayMessage } from './lib/utils';

class AccelPayIntegration {
  public env = 'stage';
  public api;
  public brandId;
  public config;
  private sentryTransaction;
  private sentryWait;
  private dom;

  constructor(env) {
    this.env = env;
    this.api = new client(env);
    this.brandId = apbrand.id;

    Sentry.init({
      dsn: this.env === 'local' ? '' : SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampler: ({ transactionContext }) => {
        if (transactionContext?.name === 'brand-script') {
          return 0.5;
        } else {
          return 0.01;
        }
      },
      defaultIntegrations: false,
      environment: this.env,
      normalizeDepth: 5,
    });
    Sentry.setTag('url', location.href);
    Sentry.startSpanManual(
      {
        name: 'brand-script',
      },
      span => {
        this.sentryTransaction = span;
      },
    );
    Sentry.startSpanManual(
      {
        name: 'wait',
      },
      span => {
        this.sentryWait = span;
      },
    );
  }

  async init() {
    try {
      /**
       * Fetch Config
       */
      const res = await this.api.get(`/brands/${this.brandId}/settings`);
      if (res.ok) {
        const rawConfig = await res.json();
        const config: any = Object.keys(rawConfig).reduce((acc, key) => {
          if (key === 'brand_cart_url') {
            acc['url'] =
              this.env === 'local' || location.host.includes('localhost')
                ? `http://${location.host}`
                : rawConfig[key];
          } else {
            acc[key.replace('brand_cart_', '')] = rawConfig[key];
          }
          return acc;
        }, {});

        this.config = config;
        this.dom = new Dom(this.brandId, this.config, this.env);
        this.initMessageListener();

        // Check brand domain mimatches
        // if (
        //   config.brand_domain &&
        //   !location.hostname.includes('accelpay') &&
        //   !config.brand_domain.includes(location.hostname)
        // ) {
        //   Sentry.captureEvent({
        //     message: 'Brand Domain Mismatch',
        //     level: 'warning',
        //   });
        // }

        // Run the integration setup once the page has loaded
        if (
          !config.conditional_load ||
          (config.conditional_load && eval(config.conditional_load)())
        ) {
          if (
            document.readyState === 'complete' ||
            document.readyState === 'interactive'
          ) {
            // Call on next available tick
            this.setup();
          } else {
            document.addEventListener('DOMContentLoaded', () => this.setup());
          }
        }
      } else {
        console.log('Could not fetch brand config');
      }
    } catch (e) {}
  }

  async initMessageListener() {
    /** Handle messages */
    const clientMessageHandler = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      console.debug('[brand.js] received message:', e.data);

      if (action === 'bc-close-main') {
        this.dom.closeMain();
      } else if (action === 'bc-close-sidebar') {
        this.dom.closeSidebar();
      } else if (action === 'bc-begin-checkout') {
        this.dom.closeCart();
        this.dom.openMain();
      } else if (action === 'bc-variant-data') {
        this.dom.updateEventsFromBrandConfig(value);
      } else if (action === 'bc-back-to-cart') {
        this.dom.closeMain();
        this.dom.openCart();
      } else if (action === 'bc-open') {
        this.dom.openCart();
      } else if (action === 'bc-close-modal') {
        this.dom.closeModal();
      }

      fbPixelHandler(this.config, e);
      googleAdsHandler(e, this.config?.google_ad_id);
      if (this.env === 'production') {
        klaviyoHandler(e);
      }
    };

    if (window.AP_EVENT_LISTENER) {
      window.removeEventListener('message', window.AP_EVENT_LISTENER, false);
    }
    window.addEventListener('message', clientMessageHandler, false);
    window.AP_EVENT_LISTENER = clientMessageHandler;
  }

  async setup() {
    try {
      this.sentryWait.end();
      console.debug('[brand.js] init', this.config);

      wixListener(this.dom);
      if (this.config.is_spa) {
        spaListener(this.dom);
      }

      await this.dom.attachAllElements();
      this.dom.attachTriageFn();
      if (
        !this.config.disable_ga &&
        this.config.brand_google_analytics_id &&
        this.env !== 'local'
      ) {
        this.dom.attachGoogleAnalytics();
      }
      if (this.env === 'production') {
        this.dom.attachKlaviyo();
      }

      const urlParams = new URLSearchParams(window.location.search);
      const open = urlParams.get('open');
      if (open) {
        this.dom.openMain();
      }
    } catch (e) {
      console.error('[brand.js] load failed: ', e);
    } finally {
      this.sentryTransaction.end();
    }
  }
}

export default AccelPayIntegration;
