import { h } from 'preact';
import styled, { css } from 'styled-components';

const Root = styled.button`
  border: none;
  background: none !important;
  justify-content: flex-start;
  z-index: 10000;
  cursor: pointer;
  padding: 6px;
`;

const Box = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ViewAccountButton = ({ config, openAccount }: any) => {
  return (
    <Root
      className="accelpay-view-account"
      config={config}
      onClick={() => {
        if (openAccount) {
          openAccount();
        }
      }}
    >
      <Box>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke={config.icon_svg_color || '#000'}
          style={{ width: '25px', height: '25px', margin: '3px' }}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </Box>
    </Root>
  );
};

export default ViewAccountButton;
