export const fbPixelHandler = (config, event) => {
  const { action, value } = event.data;

  if (window.fbq && config.fb_pixel_events) {
    if (action === 'bc-begin-checkout') {
      window.fbq('track', 'InitiateCheckout');
    } else if (action === 'bc-add-address') {
      window.fbq('track', 'AddAddressInfo');
    } else if (action === 'bc-sale') {
      const { payload } = value;
      window.fbq('track', 'Purchase', {
        value: payload.total / 100,
        currency: 'USD',
      });
    }
  }
};
