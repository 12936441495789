import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { isAccelPayMessage } from '../lib/utils';
import { formatPrice } from '../lib/utils';

const PriceLabel = ({
  variantId,
  variantIds,
  collectionId,
  mode,
}: {
  variantId?: string;
  variantIds?: string[];
  collectionId?: string;
  mode: string;
}) => {
  const [min, setMin] = useState<number>();
  const [max, setMax] = useState<number>();
  const [unavailable, setUnavailable] = useState(false);
  const [locale, setLocale] = useState('en-US');
  const [currency, setCurrency] = useState('USD');

  useEffect(() => {
    const handleMessage = e => {
      if (!isAccelPayMessage(e)) {
        return;
      }

      const { action, value } = e.data;
      if (action === 'bc-variant-data') {
        if (variantId) {
          const variant = value[variantId];
          if (variant) {
            setUnavailable(variant.inventoryStatus === 'unavailable');
            setMin(variant.priceMin);
            setMax(variant.priceMax);
          }
        } else if (variantIds) {
          let allVariantsUnavailable = true;
          const { min, max } = variantIds.reduce(
            (acc, id) => {
              const variant = value[id];
              if (variant) {
                if (variant.inventoryStatus !== 'unavailable') {
                  allVariantsUnavailable = false;
                  acc.min =
                    acc.min === null
                      ? variant.priceMin
                      : Math.min(variant.priceMin, acc.min);
                  acc.max = Math.max(variant.priceMax, acc.max);
                }
              }
              return acc;
            },
            { min: null, max: null },
          );
          setUnavailable(allVariantsUnavailable);
          setMin(typeof min === 'number' ? min : 0);
          setMax(typeof max === 'number' ? max : 0);
        }
      } else if (collectionId && action === 'bc-collection-data') {
        const collection = value[collectionId];
        if (collection) {
          setMin(collection.price);
          setMax(collection.price);
        }
      } else if (action === 'bc-location') {
        const { locale, currency } = value;
        setLocale(locale);
        setCurrency(currency);
      }
    };

    window.addEventListener('message', handleMessage, false);
    window.apbrand.listeners?.push(handleMessage);
  }, []);

  return (
    <span className="accelpay-price-label">
      {unavailable
        ? 'Unavailable'
        : mode === 'min' && min
          ? `${formatPrice(min, locale, currency)}`
          : mode === 'max' && max
            ? `${formatPrice(max, locale, currency)}`
            : mode === 'range' && min && max && min !== max
              ? `${formatPrice(min, locale, currency)} - ${formatPrice(
                  max,
                  locale,
                  currency,
                )}`
              : min !== undefined
                ? `${formatPrice(min, locale, currency)}`
                : ''}
    </span>
  );
};

export default PriceLabel;
