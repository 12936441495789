import { h } from 'preact';
import { useState } from 'preact/hooks';
import AddToCartButton from './AddToCartButton';
import styled, { css } from 'styled-components';

const RadioButton = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 8px 0px;
  min-width: 60px;
  width: calc(33.33% - 8px);
  margin: 4px;
  border: 2px solid transparent;

  ${props =>
    props.selected &&
    css`
      border: 2px solid black;
      font-weight: bold;
    `}
`;

const AddToCartRadioButtons = ({
  listingId,
  variantIds,
  variantLabels,
  config,
  iframe,
  openCart,
  children,
}: any) => {
  const [selectedVariantId, setSelectedVariantId] = useState<string>(
    variantIds[0],
  );

  if (!listingId || !variantIds) {
    return null;
  }

  return (
    <div className="accelpay-radio-buttons" style={{ width: '100%' }}>
      <div
        className="accelpay-radio-button"
        style={{ display: 'flex', marginBottom: '8px' }}
      >
        {variantIds?.map((variantId: string, i: number) => (
          <RadioButton
            selected={variantId === selectedVariantId}
            onClick={() => setSelectedVariantId(variantId)}
          >
            {variantLabels[i]}
          </RadioButton>
        ))}
      </div>
      <AddToCartButton
        fullWidth
        listingId={listingId}
        variantId={selectedVariantId}
        config={config}
        iframe={iframe}
        openCart={openCart}
      >
        {children}
      </AddToCartButton>
    </div>
  );
};

export default AddToCartRadioButtons;
