import { h } from 'preact';
import { useState } from 'preact/hooks';
import AddToCartButton from './AddToCartButton';
import styled from 'styled-components';
import { useEffect } from 'react';

const Select = styled.select`
  width: 100%;
  border: 1px solid #818a91;
  vertical-align: middle;
  background-color: #ffffff;
  minheight: 40px;
  border-radius: 3px;
  color: #373a3c;
  font-size: 14px;
  cursor: pointer;
  padding: 10px;
`;

const AddToCartVariantsDropdown = ({
  listingId,
  config,
  iframe,
  openCart,
  children,
}: any) => {
  const optionsMap = config.product_options
    ? JSON.parse(config.product_options)
    : {};
  const variantsMap = config.variant_ids ? JSON.parse(config.variant_ids) : {};
  const [selectedOptions, setSelectedOptions] = useState(
    optionsMap[listingId]?.map(({ options }) => options[0]),
  );
  const [selectedVariantId, setSelectedVariantId] = useState<string>(
    selectedOptions.reduce(
      (p, c) => (p && p[c]) || null,
      variantsMap[listingId],
    ),
  );

  useEffect(() => {
    setSelectedVariantId(
      selectedOptions.reduce(
        (p, c) => (p && p[c]) || null,
        variantsMap[listingId],
      ),
    );
  }, [selectedOptions]);

  if (!listingId) {
    return null;
  }

  return (
    <div>
      <div className="accelpay-dropdowns" style={{ marginBottom: '8px' }}>
        {listingId &&
          optionsMap[listingId]?.map((productOption, i) => {
            const { name, options } = productOption;
            return (
              <div className="accelpay-dropdown">
                <p
                  className="accelpay-dropdown-label"
                  style={{
                    margin: '8px 0px',
                    textAlign: 'left',
                    fontSize: '12px',
                    color: '#444',
                  }}
                >
                  {name}
                </p>
                <Select
                  title="Variant Dropdown"
                  value={selectedOptions[name]}
                  onChange={e =>
                    setSelectedOptions((options: any) => {
                      const updatedOptions = [...options];
                      updatedOptions[i] = e.target.value;
                      return updatedOptions;
                    })
                  }
                >
                  {options.map(option => (
                    <option value={option}>{option}</option>
                  ))}
                </Select>
              </div>
            );
          })}
      </div>
      <AddToCartButton
        fullWidth
        listingId={listingId}
        variantId={selectedVariantId}
        config={config}
        iframe={iframe}
        openCart={openCart}
      >
        {children}
      </AddToCartButton>
    </div>
  );
};

export default AddToCartVariantsDropdown;
