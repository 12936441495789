import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import localCookie from 'js-cookie';
import styled from 'styled-components';
import { LOCATIONS, COUNTRY_COOKIE_KEY } from '../lib/constants';
import messenger from '../messenger';

const Select = styled.select`
  border: 1px solid #818a91;
  vertical-align: middle;
  background-color: #ffffff;
  minheight: 40px;
  border-radius: 3px;
  color: #373a3c;
  font-size: 14px;
  cursor: pointer;
  padding: 6px;
`;

const CountrySwitcher = ({ iframe, config }) => {
  const preference = LOCATIONS.find(l => l.locale === navigator.language);
  const [selected, setSelected] = useState(
    localCookie.get(COUNTRY_COOKIE_KEY) ||
      preference.country ||
      LOCATIONS[0].country,
  );

  useEffect(() => {
    const location = LOCATIONS.find(location => selected === location.country);
    messenger.location(window, '*', {
      ...location,
    });
    messenger.location(iframe.contentWindow, config.url, {
      ...location,
    });
    localCookie.set(COUNTRY_COOKIE_KEY, selected, { sameSite: 'strict' });
  }, [selected]);

  return (
    <Select
      className="accelpay-country-switcher"
      title="Country switcher"
      value={selected}
      onChange={e => setSelected(e.target.value)}
    >
      {LOCATIONS.map(location => (
        <option value={location.country}>{location.label}</option>
      ))}
    </Select>
  );
};

export default CountrySwitcher;
